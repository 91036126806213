import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import BlogsFakeData from '../../FakeData/BlogsFakeData';
import './Blogs.css';
import BlogsCard from './BlogsCard/BlogsCard';

const Blogs = ( {showAll} ) => {
    const showBlogs = showAll ? BlogsFakeData : BlogsFakeData.slice(0, 3);
    const [blogsNews, setBlogsNews] = useState(showBlogs);
    return (
        <section className="blogs-section">
            <div className="container">
                <h2 className="section-title text-center" data-aos="fade-up" data-aos-anchor-placement="top-bottom"><span>Our</span> Latest Blogs</h2>
                <div className="latest-blogs-wrapper">
                    <div className="latest-blogs-inner">
                        <div className="latest-blogs-news">
                            <div className="row">
                                {
                                    blogsNews.map(blogsNews => <BlogsCard key={blogsNews.id} blogsNews={blogsNews}></BlogsCard>)
                                }
                            </div>
                            {
                                !showAll && (
                                    <div className="moreBlogs-btn">
                                        <Link className="blog-btn" to={`/blog`}>More Blogs</Link>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Blogs;