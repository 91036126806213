import React from 'react';
import ContactUs from '../../Home/ContactUs/ContactUs';
import HeaderTop from '../../Share/HeaderTop/HeaderTop';
import Footer from '../../Share/Footer/Footer';
import Navbar from '../../Share/Navbar/Navbar';
import Subscriber from '../../Share/Subscriber/Subscriber';
import ContactHeroArea from '../ContactHeroArea/ContactHeroArea';
import NavigationMap from '../NavigationMap/NavigationMap';

const Contact = () => {
    return (
        <>
            <HeaderTop></HeaderTop>
            <Navbar></Navbar>
            <ContactHeroArea></ContactHeroArea>
            <ContactUs></ContactUs>
            <NavigationMap></NavigationMap>
            <Subscriber></Subscriber>
            <Footer></Footer>
        </>
    );
};

export default Contact;