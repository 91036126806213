var GalleryFakeData = [
    {
        id: 1,
        image: 'https://i.ibb.co/pyvg8KL/category-img1.jpg',
        category: 'All'
    },
    {
        id: 2,
        image: 'https://i.ibb.co/d06d9GD/FB-IMG-1583426548630.jpg',
        category: 'All'
    },
    {
        id: 3,
        image: 'https://i.ibb.co/d77b736/fluid-micro-concrete-1511416405-3470726.jpg',
        category: 'All'
    },
    {
        id: 4,
        image: 'https://i.ibb.co/0nvJdKZ/retroffiting.jpg',
        category: 'All'
    },
    {
        id: 5,
        image: 'https://i.ibb.co/r6RstCn/cp2.jpg',
        category: 'All'
    },
    {
        id: 6,
        image: 'https://i.ibb.co/JjBmP3m/FB-IMG-1607680496752.jpg',
        category: 'All'
    },
    {
        id: 7,
        image: 'https://i.ibb.co/r6RstCn/cp2.jpg',
        category: 'Gallery1'
    },
    {
        id: 8,
        image: 'https://i.ibb.co/8cJVfm2/DSC00116.jpg',
        category: 'Gallery1'
    },
    {
        id: 9,
        image: 'https://i.ibb.co/fYxN1bd/torch-on-installation-1536x1066.jpg',
        category: 'Gallery1'
    },
    {
        id: 10,
        image: 'https://i.ibb.co/Vgj1TT8/20210105-113740.jpg',
        category: 'Gallery1'
    },
    {
        id: 11,
        image: 'https://i.ibb.co/fYxN1bd/torch-on-installation-1536x1066.jpg',
        category: 'Gallery2'
    },
    {
        id: 12,
        image: 'https://i.ibb.co/dt4F69P/DSC03970.jpg',
        category: 'Gallery2'
    },
    {
        id: 13,
        image: 'https://i.ibb.co/T05RjQk/DSC03779.jpg',
        category: 'Gallery2'
    },
    {
        id: 14,
        image: 'https://i.ibb.co/8cJVfm2/DSC00116.jpg',
        category: 'Gallery3'
    },
    {
        id: 15,
        image: 'https://i.ibb.co/nDyhnQd/FB-IMG-1591364230151.jpg',
        category: 'Gallery3'
    },
    {
        id: 16,
        image: 'https://i.ibb.co/sCBwXBY/glo-cementitious-grouting-13.webp',
        category: 'Gallery3'
    },
    {
        id: 17,
        image: 'https://i.ibb.co/fYxN1bd/torch-on-installation-1536x1066.jpg',
        category: 'Gallery3'
    },
    {
        id: 18,
        image: 'https://i.ibb.co/Vgj1TT8/20210105-113740.jpg',
        category: 'Gallery3'
    },
    {
        id: 19,
        image: 'https://i.ibb.co/tJ3tFyw/DSC02454-FILEminimizer-Copy.jpg',
        category: 'Gallery4'
    },
    {
        id: 20,
        image: 'https://i.ibb.co/Vgj1TT8/20210105-113740.jpg',
        category: 'Gallery4'
    },
    {
        id: 21,
        image: 'https://i.ibb.co/8MFCBNb/chemical-re-bar-fixing-250x250.jpg',
        category: 'Gallery4'
    }
];

export default GalleryFakeData;