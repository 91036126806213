import React from 'react';
import './GalleryHeroArea.css';

const GalleryHeroArea = () => {
    return (
        <section className="gallery-hero-section">
            <div className="gallery-bgOverlay">
                <div className="container">
                    <div className="galleryHero-content text-center">
                        <h5 data-aos="fade-up">Home/ Gallery</h5>
                        <h2 data-aos="fade-down">Gallery</h2>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GalleryHeroArea;