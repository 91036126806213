import React from 'react';
import Footer from '../../Share/Footer/Footer';
import HeaderTop from '../../Share/HeaderTop/HeaderTop';
import Navbar from '../../Share/Navbar/Navbar';
import About from '../About/About';
import HeroArea from '../HeroArea/HeroArea';
import Services from '../Services/Services';
import Subscriber from '../../Share/Subscriber/Subscriber';
import CompleteWorking from '../CompleteWorking/CompleteWorking';
import CompanyClients from '../CompanyClients/CompanyClients';
import Blogs from '../Blogs/Blogs';
import Products from '../Products/Products';
import Gallery from '../Gallery/Gallery';
import ContactUs from '../ContactUs/ContactUs';
// import ClientsFeedBack from '../ClientsFeedBack/ClientsFeedBack/ClientsFeedBack';

const Home = () => {
    return (
        <>
            <HeaderTop></HeaderTop>
            <Navbar></Navbar>
            <HeroArea></HeroArea>
            <About></About>
            <Services></Services>
            <Products></Products>
            <Gallery></Gallery>
            <CompanyClients></CompanyClients>
            <CompleteWorking></CompleteWorking>
            <Blogs></Blogs>
            <ContactUs></ContactUs>
            {/* <ClientsFeedBack></ClientsFeedBack> */}
            <Subscriber></Subscriber>
            <Footer></Footer>
        </>
    );
};

export default Home;