import React from 'react';
import './ProductHeroArea.css';

const ProductHeroArea = () => {
    return (
        <section className="product-hero-section">
            <div className="product-bgOverlay">
                <div className="container">
                    <div className="productHero-content text-center">
                        <h5 data-aos="fade-up">Home/ Product</h5>
                        <h2 data-aos="fade-down">Product</h2>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProductHeroArea;