import React from 'react';
import './Subscriber.css';
import emailjs from 'emailjs-com';

const Subscriber = () => {
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('gmail', 'template_n20lh2u', e.target, 'user_oXA1cUdrVGytSgDCWXWwq')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
    };
    return (
        <div className="subscriber">
            <div className="container">
                <div className="subscriber-wrapper">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                            <div className="subscriber-content">
                                <h4>Mount Construction Chemicals Engineering Limited</h4>
                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Accusantium, quae.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                            <div className="input-box">
                                <form onSubmit={sendEmail}>
                                    <input type="email" name="email" autoComplete="off" placeholder="Enter your e-mail" />
                                    <input className="subscribe-btn" type="submit" value="Submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Subscriber;