import React from 'react';
import './FooterBox2.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const FooterBox2 = () => {
    return (
        <div className="footer-box2">
            <h4 className="footer_title">SERVICES</h4>
            <div className="footerBox2_menu">
                <ul>
                    <li><FontAwesomeIcon className="icon" icon={faAngleRight} /><Link to={`/services`}>Concrete Repair & Renovation</Link></li>
                    <li><FontAwesomeIcon className="icon" icon={faAngleRight} /><Link to={`/services`}>Surface Treatments</Link></li>
                    <li><FontAwesomeIcon className="icon" icon={faAngleRight} /><Link to={`/services`}>Water Proofing</Link></li>
                    <li><FontAwesomeIcon className="icon" icon={faAngleRight} /><Link to={`/services`}>Protective Coatings</Link></li>
                    <li><FontAwesomeIcon className="icon" icon={faAngleRight} /><Link to={`/services`}>Polish Concrete</Link></li>
                    <li><FontAwesomeIcon className="icon" icon={faAngleRight} /><Link to={`/services`}>Fair Face Work</Link></li>
                </ul>
            </div>
        </div>
    );
};

export default FooterBox2;