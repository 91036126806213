var ServiceFakeData = [
    {
        id: 1,
        serviceTitle: 'Admixture',
        serviceDescription: 'The concrete admixture are also called concrete additives. These materials are added to the concrete before mixing or during the mixing process to improve the properties of the fresh concrete. The dosage of concrete additive is generally not more than 5% of the cement dosage. The characteristic of concrete additives are characterized by many varieties and small amounts, which have a great influence on the performance of the concrete.',
        serviceDesc: 'In addition it has the characteristics of less investment, quick effect and remarkable technical and economic befits. Reasonable use of admixtures in concrete can achieve various technical economic benefits such as savings cement, saving  anergy, shortening the construction period, improving concrete properties and construction operating conditions.',
        serviceItem1: 'Plasticizers Admixture',
        serviceItem2: 'Super Plasticizers Admixture',
        serviceItem3: 'Hyper Plasticizers Admixture',
        serviceItem4: 'Water Proofing Admixture',
        serviceItem5: 'Plasticized Expending Grout Admixture',
        category: 'Admixture'
    },
    {
        id: 2,
        serviceTitle: 'Water Proofing',
        serviceDescription: 'Waterproofing is the process of making an object or structure water proof or water resistant so that it remains relatively unaffected by water or resisting the ingress off water under specified conditions. Water resistant and water proof often refer to penetration of water in its liquid state and possibly under pressure, wheres damp proof refers to resistant to humidity or dampness.',
        serviceDesc: 'Permission of water vapor through a material or structure is reported as a moisture vapor transmission rate. Modern items may be water proof by applying water replant coating or by sealing seams width bituminous system.',
        serviceItem1: 'Cementitious Water Proofing System',
        serviceItem2: 'Polyurethiene Water Proofing System',
        serviceItem3: 'Self-adhesive Bituminous Water Proofing Membrane',
        serviceItem4: 'Torch Applied Slade Bituminous Water Proofing Membrane',
        serviceItem5: 'Torch Applied Non Slade Bituminous Water Proofing Membrane',
        category: 'Water Proofing'
    },
    {
        id: 3,
        serviceTitle: 'Retrofitting',
        serviceDescription: 'The word retrofit means to apply new technologies to an older system. Retrofit is a process of adding some new features that were not there before. Retrofitting in construction industry refers to re-strengthening of existing structure to make them seismic resistant. Retrofitting is an economic approach to increase life span of existing structure rather than redeveloping it.',
        serviceDesc: 'There are various building structures of public, private and historical importance. If private and public structures get damaged, in extreme cases gray can be dismantled. But incase of structure of historical importance, they can not be dismantled. And here some only way two save these structure retrofitting. Retrofit in structure is done to increase the survivability functionality. The applications include different types of retrofitting, beam footing column slab arch gardur pier etc.',
        serviceItem1: 'Bridges Retrofitting',
        serviceItem2: 'Buildings Industrial Structure Retrofitting',
        serviceItem3: 'Transport Structure in urban areas Retrofitting',
        serviceItem4: 'Earth Retaining Structure Retrofitting',
        serviceItem5: 'Marine Structures Retrofitting',
        category: 'Retrofitting'
    },
    {
        id: 4,
        serviceTitle: 'Grouts and Anchors',
        serviceDescription: 'Grouts concrete anchors are post installed bars utilizing a high strength grout for the bonding material. The advantage of using a grout various an epoxy is cement grouts does not break down in heigh heat areas and can be pumped into dep embedded holes easier than epoxy.',
        serviceDesc: 'For injecting into cracks, Honeycombs and cavities in concrete structures. It can also be used for grouting base plates with very narrow gaps (<10mm).',
        serviceItem1: 'Plastic shrinkage compensated',
        serviceItem2: 'Develop high early strength',
        serviceItem3: 'Free flow ensures high level of contract with load bearing area',
        serviceItem4: 'Ultra rapid strength gain, Vibration resistant, Corrosion resistant',
        serviceItem5: 'Tolerant of wet and damp conditions, Can be placed underwater and in damp conditions',
        category: 'Grouts and Anchors'
    },
    {
        id: 5,
        serviceTitle: 'Industrial Flooring (Epoxy, PU & Hardener)',
        serviceDescription: 'Flooring is the general term for a permanent covering of a floor or for the work of installed such a floor covering. Floor covering is a term to generically describe any finish material applied over a floor structure to provide a or in surface. Both term are used interchangeably but floor covering refers more to lost late materials.',
        serviceDesc: 'The floor under the flooring is called the sub floor which provides the support for the flooring. Special purpose sub floors like floating floors, raised floors may be laid upon another underlying sub floor which provides the structural strength.',
        serviceItem1: 'Floor hardener',
        serviceItem2: 'Epoxy flooring',
        serviceItem3: 'PU flooring',
        serviceItem4: '3d floor',
        serviceItem5: 'Polish concrete flooring',
        category: 'Industrial Flooring'
    },
    {
        id: 6,
        serviceTitle: 'Joint Sealants',
        serviceDescription: 'The most widely accepted definition of a joint sealants today is a material that minimizes both infiltration of surface water and in compressible material into the joint system. Secondarily, Sealants are also purported to reduce the potential for dowel bar corrosion by reducing entrance of joint sealants chemicals.',
        serviceDesc: 'There are some earned claims that joint sealants prevent surface water from entering the joint system, but modern vacuum test clearly show that no sealants will provide a perfectly water tight seal.',
        serviceItem1: 'Epoxy joint sealants',
        serviceItem2: 'PU joint sealants',
        serviceItem3: 'Fire proof joint sealants',
        serviceItem4: '',
        serviceItem5: '',
        category: 'Joint Sealants'
    },
    {
        id: 7,
        serviceTitle: 'Surface Treatments',
        serviceDescription: 'There are many ways of treating concrete floors or concrete surface. These depends on the performance which is required for the designated by solving effective criteria. These could be polished concrete and epoxy coating a regin or just even sealed.',
        serviceDesc: 'Such treatment has a positive effect on the concrete surface functionality and other similarly important issues. On of the applicator that trades in concrete, mixtures and chemicals for construction.',
        serviceItem1: 'Curing compound',
        serviceItem2: 'Sutter release agent',
        serviceItem3: 'Multi purpose cleaning agent',
        serviceItem4: '',
        serviceItem5: '',
        category: 'SurfaceTreatments'
    },
    {
        id: 8,
        serviceTitle: 'Concrete Repair & Renovation',
        serviceDescription: 'Concrete repair is the process of fixing a hardened concrete surface that over time has lost the ability to hold the binding concrete materials together due to damage or environmental exposure. Concrete repair is appropriate for cracks, physical impact, chipped out or surface scaling.',
        serviceDesc: 'There are various ways to repair a concrete surface, which required some specific procedure composite repair, corrosion repair, concrete coating, polyaspartic costing, construction joint, abatement.',
        serviceItem1: 'Cementicious concrete repair',
        serviceItem2: 'Epoxy concrete repair',
        serviceItem3: '',
        serviceItem4: '',
        serviceItem5: '',
        category: 'Concrete Repair & Renovation'
    },
    {
        id: 9,
        serviceTitle: 'Protective Coatings',
        serviceDescription: 'Reinforced concrete is a composite material. Its structural performance is realised only when concrete and steel act in unison during the service life of the  structure. The compressive and tensile loads are carried by concrete and steel respectively. Steel protects concrete from cracking under tensile load and concrete protects steel from corrosion by providing and alkaline environment around it.',
        serviceDesc: 'Various external hostile environmental substance, such as water carbon dioxide, oxygen, chlorides, sulphide and biological organisms are transported from the atmosphere into the concrete and attack steel and concrete in different mechanisms causing premature deterioration of reinforce concrete.',
        serviceItem1: 'Water Repellent Coating',
        serviceItem2: 'Anti- Salinity',
        serviceItem3: 'Epoxy resin coating ',
        serviceItem4: 'Hygenic chemical resistant coating ',
        serviceItem5: 'Coal tar extended epoxy resin coating',
        category: 'Protective Coatings'
    },
    {
        id: 10,
        serviceTitle: 'Polish Concrete',
        serviceDescription: 'Polished concrete is a multi-step process where a concrete floor is mechanically ground, honed and polished with bonded abrasives in order to cut a concrete floors surface. It is then refined with each cut in order to achieve a specified level of appearance.',
        serviceDesc: 'This process also includes the use of a penetrant chemical known as a hardener. The concrete densifier/hardener penetrates into the concrete and creates a chemical reaction to help harden and dust-proof the surface. During concrete polishing, the surface is processed through a series of steps (in general a minimum of four grinding steps of processing is considered polished concrete) utilizing progressively finer grinding tools. The grinding tools are progressive grits of industrial diamonds in a bonded material such as metal/hybrid/resin often referred to as diamond polishing pads.  ',
        serviceItem1: 'Cream Polish',
        serviceItem2: 'Salt & Paper Polish',
        serviceItem3: 'Aggregate Polish',
        serviceItem4: 'Hardener Polish',
        serviceItem5: '',
        category: 'Polish Concrete'
    },
    {
        id: 11,
        serviceTitle: 'Fair Face Work',
        serviceDescription: 'Fair-faced concrete, also known as decorative concrete, is named for its decorative effect. It belongs to one-time casting molding, without any external decoration, directly adopts the natural surface effect of cast-in-place concrete as the veneer.',
        serviceDesc: 'Fair-faced concrete is the most advanced form of concrete material.',
        serviceItem1: 'Fair face concrete',
        serviceItem2: 'Fair face mortar',
        serviceItem3: 'Fair face skim coat',
        serviceItem4: '',
        serviceItem5: '',
        category: 'Fair Face Work'
    }
];

export default ServiceFakeData;