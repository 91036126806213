import React from 'react';
import './CompleteWorking.css';
import CountUp from 'react-countup';

const CompleteWorking = () => {
    return (
        <section className="complete-work-section">
            <div className="completeWork-bgOverlay">
                <div className="container">
                    <div className="completeWork-content">
                        <div className="single-completeWork" data-aos="fade-up">
                            <h1><CountUp end={150} duration={5} /></h1>
                            <h5>Projects Completed</h5>
                        </div>
                        <div className="single-completeWork" data-aos="fade-down">
                            <h1><CountUp end={120} duration={5} /></h1>
                            <h5>Really Happy Clients</h5>
                        </div>
                        <div className="single-completeWork" data-aos="fade-up">
                            <h1><CountUp end={250} duration={5} /></h1>
                            <h5>Total Tasks Completed</h5>
                        </div>
                        <div className="single-completeWork" data-aos="fade-down">
                            <h1><CountUp end={5} duration={5} /></h1>
                            <h5>In House Professionals</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CompleteWorking;