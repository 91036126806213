import React from 'react';
import Blogs from '../../Home/Blogs/Blogs';
import HeaderTop from '../../Share/HeaderTop/HeaderTop';
import Footer from '../../Share/Footer/Footer';
import Navbar from '../../Share/Navbar/Navbar';
import Subscriber from '../../Share/Subscriber/Subscriber';
import BlogHeroArea from '../BlogHeroArea/BlogHeroArea';

const Blog = ({showAll}) => {
    return (
        <>
            <HeaderTop></HeaderTop>
            <Navbar></Navbar>
            <BlogHeroArea></BlogHeroArea>
            <Blogs showAll></Blogs>
            <Subscriber></Subscriber>
            <Footer></Footer>
        </>
    );
};

export default Blog;