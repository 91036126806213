import React from 'react';
import './ProductsCard.css';

const ProductsCard = ( props ) => {
    const { image, productTitle } = props.products;
    return (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" data-aos="zoom-in">
            <div className="single-products-category">
                <div className="products-category-image">
                    <img src={image} className="img-fluid" alt=""/>
                    <div className="products-category-content">
                        <div className="project-meta">
                            <h3>{productTitle}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductsCard;