import React from 'react';
import './CompanyClients.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import clientImage1 from '../../../Assets/CompanyClients-img/clients-1.png';
import clientImage2 from '../../../Assets/CompanyClients-img/clients-2.png';
import clientImage3 from '../../../Assets/CompanyClients-img/clients-3.png';
import clientImage4 from '../../../Assets/CompanyClients-img/clients-4.jpg';
import clientImage5 from '../../../Assets/CompanyClients-img/clients-5.png';
import clientImage6 from '../../../Assets/CompanyClients-img/clients-6.png';
import clientImage7 from '../../../Assets/CompanyClients-img/clients-7.png';
import clientImage8 from '../../../Assets/CompanyClients-img/clients-8.png';
import clientImage9 from '../../../Assets/CompanyClients-img/clients-9.png';
import clientImage10 from '../../../Assets/CompanyClients-img/clients-10.jpg';
import clientImage11 from '../../../Assets/CompanyClients-img/clients-11.png';
import clientImage12 from '../../../Assets/CompanyClients-img/clients-12.png';
import clientImage13 from '../../../Assets/CompanyClients-img/clients-13.png';
import clientImage14 from '../../../Assets/CompanyClients-img/clients-14.png';
import clientImage15 from '../../../Assets/CompanyClients-img/clients-15.png';
import clientImage16 from '../../../Assets/CompanyClients-img/clients-16.png';
import clientImage17 from '../../../Assets/CompanyClients-img/clients-17.png';
import AOS from 'aos';

const CompanyClients = () => {
    AOS.init({
        delay: 1,
        duration: 700,
    });
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 1200,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (
        <section className="company-clients">
            <div className="container">
                <h2 className="section-title text-center mb-4" data-aos="fade-up" data-aos-anchor-placement="top-bottom"><span>Our</span> Clients</h2>
                <div data-aos="zoom-in-up">
                    <Slider {...settings}>
                        <div>
                            <img src={clientImage1} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage3} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage4} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage5} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage2} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage8} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage9} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage10} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage12} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage13} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage14} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage11} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage15} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage6} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage16} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage7} className="img-fluid" alt=""/>
                        </div>
                        <div>
                            <img src={clientImage17} className="img-fluid" alt=""/>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default CompanyClients;