var BlogsFakeData = [
    {
        id: 1,
        blogsImage: 'https://i.ibb.co/0nvJdKZ/retroffiting.jpg',
        blogsTitle: 'Retrofitting',
        blogsDescription: 'The word retrofit means to apply new technologies to an older system. Retrofit is a process of adding some new features that were not there before.',
        blogsPost: 'Retrofitting in construction industry refers to re-strengthening of existing structure to make them seismic resistant. Retrofitting is an economic approach to increase life span of existing structure rather than redeveloping it.'
    },
    {
        id: 2,
        blogsImage: 'https://i.ibb.co/d4DT1Vh/water-proffing.jpg',
        blogsTitle: 'Water Proofing',
        blogsDescription: 'Waterproofing is the process of making an object or structure water proof or water resistant so that it remains relatively unaffected by water',
        blogsPost: 'or resisting the ingress off water under specified conditions. Water resistant and water proof often refer to penetration of water in its liquid state and possibly under pressure, wheres damp proof refers to resistant to humidity or dampness.'
    },
    {
        id: 3,
        blogsImage: 'https://i.ibb.co/p1mr8vN/Industrial-Epoxy-Flooring.png',
        blogsTitle: 'Flooring',
        blogsDescription: 'Flooring is the general term for a permanent covering of a floor or for the work of installed such a floor covering. Floor covering is ',
        blogsPost: 'a term to generically describe any finish material applied over a floor structure to provide a or in surface. Both term are used interchangeably but floor covering refers more to lost late materials.'
    },
    {
        id: 4,
        blogsImage: 'https://i.ibb.co/NCmJdHt/grouts-anchor.jpg',
        blogsTitle: 'Grouts & Anchor',
        blogsDescription: 'Grouts concrete anchors are post installed bars utilizing a high strength grout for the bonding material. The advantage of using a grout various an epoxy is cement grouts does not break down',
        blogsPost: ' in heigh heat areas and can be pumped into dep embedded holes easier than epoxy.'
    },
    {
        id: 5,
        blogsImage: 'https://i.ibb.co/4Phfz27/concrete-repair-renovation.jpg',
        blogsTitle: 'Concrete Repair & Renovation',
        blogsDescription: 'Concrete repair is the process of fixing a hardened concrete surface that over time has lost the ability to hold the binding concrete materials together due to damage or environmental exposure.',
        blogsPost: 'Concrete repair is appropriate for cracks, physical impact, chipped out or surface scaling.'
    },
    {
        id: 6,
        blogsImage: 'https://i.ibb.co/9GWVrQj/protative-coating.jpg',
        blogsTitle: 'Protective Coatings',
        blogsDescription: 'Reinforced concrete is a composite material. Its structural performance is realised only when concrete and steel act in unison during the service life of the  structure.',
        blogsPost: 'The compressive and tensile loads are carried by concrete and steel respectively. Steel protects concrete from cracking under tensile load and concrete protects steel from corrosion by providing and alkaline environment around it.'
    }
];

export default BlogsFakeData;