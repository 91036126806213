import React from 'react';
import './ServicesMenu.css';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ServicesMenu = ( {selectedMenu, setSelectedMenu} ) => {
    return (
        <div className="service-menu" data-aos="fade-down">
            <ul>
                <li className={selectedMenu === "Admixture" ? "active" : ""} onClick={() => setSelectedMenu("Admixture")}>Admixture<FontAwesomeIcon className="ser-icon" icon={faAngleDoubleRight} /></li>
                <li className={selectedMenu === "Water Proofing" ? "active" : ""} onClick={() => setSelectedMenu("Water Proofing")}>Water Proofing<FontAwesomeIcon className="ser-icon" icon={faAngleDoubleRight} /></li>
                <li className={selectedMenu === "Retrofitting" ? "active" : ""} onClick={() => setSelectedMenu("Retrofitting")}>Retrofitting<FontAwesomeIcon className="ser-icon" icon={faAngleDoubleRight} /></li>
                <li className={selectedMenu === "Grouts and Anchors" ? "active" : ""} onClick={() => setSelectedMenu("Grouts and Anchors")}>Grouts and Anchors<FontAwesomeIcon className="ser-icon" icon={faAngleDoubleRight} /></li>
                <li className={selectedMenu === "Industrial Flooring" ? "active" : ""} onClick={() => setSelectedMenu("Industrial Flooring")}>Industrial Flooring<FontAwesomeIcon className="ser-icon" icon={faAngleDoubleRight} /></li>
                <li className={selectedMenu === "Joint Sealants" ? "active" : ""} onClick={() => setSelectedMenu("Joint Sealants")}>Joint Sealants<FontAwesomeIcon className="ser-icon" icon={faAngleDoubleRight} /></li>
            </ul>
        </div>
    );
};

export default ServicesMenu;