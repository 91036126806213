import React from 'react';
import './GalleryCard.css';

const GalleryCard = ( props ) => {
    const { image } = props.galleryImg;
    return (
        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
            <div className="single-galley-image">
                <div className="gallery-image">
                    <img src={image} alt=""/>
                </div>
            </div>
        </div>
    );
};

export default GalleryCard;