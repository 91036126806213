import React from 'react';
import './BlogHeroArea.css';

const BlogHeroArea = () => {
    return (
        <section className="blogHero-section">
            <div className="blog-bgOverlay">
                <div className="container">
                    <div className="blogHero-content text-center">
                        <h5 data-aos="fade-up">Home/ Blogs</h5>
                        <h2 data-aos="fade-down">Blogs</h2>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlogHeroArea;