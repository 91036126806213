import React from 'react';
import './MoreServicesCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const MoreServicesCard = ( props ) => {
    const { serviceTitle, serviceDescription, serviceDesc, serviceItem1, serviceItem2, serviceItem3, serviceItem4, serviceItem5 } = props.serviceMoreItem;
    return (
        <div className="single-services-item">
            <h2>{serviceTitle}</h2>
            <p>{serviceDescription}</p>
            <p>{serviceDesc}</p>
            <div className="item-list">
                <ul>
                    <li><FontAwesomeIcon className="ser-list-icon" icon={faCheck} />{serviceItem1}</li>
                    <li><FontAwesomeIcon className="ser-list-icon" icon={faCheck} />{serviceItem2}</li>
                    <li><FontAwesomeIcon className="ser-list-icon" icon={faCheck} />{serviceItem3}</li>
                    <li><FontAwesomeIcon className="ser-list-icon" icon={faCheck} />{serviceItem4}</li>
                    <li><FontAwesomeIcon className="ser-list-icon" icon={faCheck} />{serviceItem5}</li>
                </ul>
            </div>
        </div>
    );
};

export default MoreServicesCard;