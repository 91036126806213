var ProductsFakeData = [
    {
        id: 1,
        image: 'https://i.ibb.co/rdK8xZx/mountbd-mountbd-mountbd-mountbd-mountbd-mountbd-mountbd-mountbd-mountbd-mountbd-mountbd-mountbd-moun.jpg',
        productTitle: 'Admixture'
    },
    {
        id: 2,
        image: 'https://i.ibb.co/tQ8SCKF/water-Proffing.jpg',
        productTitle: 'Water Proofing'
    },
    {
        id: 3,
        image: 'https://i.ibb.co/WBfzBnW/retrofitting.jpg',
        productTitle: 'Retrofitting'
    },
    {
        id: 4,
        image: 'https://i.ibb.co/WG939fm/grouts-anchors.webp',
        productTitle: 'Grouts and Anchors'
    },
    {
        id: 5,
        image: 'https://i.ibb.co/3YKYLKm/industrial-Flooring.jpg',
        productTitle: 'Industrial Flooring'
    },
    {
        id: 6,
        image: 'https://i.ibb.co/h8ZWKWn/joint-Sealants.jpg',
        productTitle: 'Joint Sealants'
    }
];

export default ProductsFakeData;