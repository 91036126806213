import React from 'react';
import './Navbar.css';
import headerLogo from '../../../Assets/headerLogo.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

const Navbar = () => {
    const [scrollingNav, setScrollingNav] = useState(false);
    const changeNavBackground = () => {
        if (window.scrollY >= 100) {
            setScrollingNav(true);
        }
        else {
            setScrollingNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", changeNavBackground);
    }, []);

    return (
        <div className={scrollingNav ? "header-menu active fixed-top" : "header-menu"}>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <Link className="navbar-brand" to="/"><img src={headerLogo} alt="headerLogo"/></Link>
                    <button className="navbar-toggler custom-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <Link to={`/home`}><a className="nav-link" href="#!">Home <span className="sr-only">(current)</span></a></Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/aboutUs`}><a className="nav-link" href="#!">About Us</a></Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/services`}><a className="nav-link" href="#!">Services</a></Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/product`}><a className="nav-link" href="#!">Products</a></Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/galleryUs`}><a className="nav-link" href="#!">Gallery</a></Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/blog`}><a className="nav-link" href="#!">Blogs</a></Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/contact`}><a className="nav-link" href="#!">Contact Us</a></Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Navbar;