import React from 'react';
import './HeaderTop.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGoogle, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';

const HeaderTop = () => {
    AOS.init({
        delay: 1,
        duration: 700,
    });
    return (
        <div className="header-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-12" data-aos="fade-up">
                        <div className="header-top-address">
                            <div className="phone"><p><FontAwesomeIcon className="icon" icon={faPhone} />+880-1718-919505, +880-1715-403993</p></div>
                            <div className="email"><p><FontAwesomeIcon className="icon" icon={faEnvelope} /><a href="mailto:info@mountbd.org">Email: info@mountbd.org</a></p></div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12" data-aos="fade-down">
                        <div className="header-top-social">
                            <ul>
                                <li><a href="https://www.facebook.com/Mount-Construction-Chemicals-Engineering-ltd-109563461278670" target="blank"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                                <li><a href="https://twitter.com/mountbd1" target="blank"><FontAwesomeIcon icon={faTwitter} /></a></li>
                                <li><a href="https://www.linkedin.com/in/mount-bd-353727211/" target="blank"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                                <li><a href="mailto:info@mountbd.org"><FontAwesomeIcon icon={faGoogle} /></a></li>
                                <li><a href="https://www.instagram.com/mountbd05/" target="blank"><FontAwesomeIcon icon={faInstagram} /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderTop;