import React, { useState } from 'react';
import GalleryFakeData from '../../FakeData/GalleryFakeData';
import './Gallery.css';
import GalleryCard from './GalleryCard/GalleryCard';
import GalleryMenubar from './GalleryMenubar/GalleryMenubar';

const Gallery = () => {
    const [galleryImg, setGallerImg] = useState(GalleryFakeData);
    const [selectGallery, setSelectGallery] = useState("All");
    
    return (
        <section className="gallery-section">
            <div className="container">
                <h2 className="section-title text-center" data-aos="fade-up" data-aos-anchor-placement="top-bottom"><span>Our</span> Gallery</h2>
                <GalleryMenubar selectGallery={selectGallery} setSelectGallery={setSelectGallery}></GalleryMenubar>
                <div className="gallery-wrapper">
                    <div className="gallery-inner"  data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
                        <div className="row">
                            {
                                galleryImg.filter((All) => selectGallery === All.category).map(galleryImg => <GalleryCard key={galleryImg.id} galleryImg={galleryImg}></GalleryCard>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Gallery;