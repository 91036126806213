import React from 'react';
import './FooterBox3.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const FooterBox3 = () => {
    return (
        <div className="footer-box3">
            <h4 className="footer_title">QUICK LINKS</h4>
            <div className="footerBox3_menu">
                <ul>
                    <li><FontAwesomeIcon className="icon" icon={faAngleRight} /><Link to={`/Home`}><a href="#!">Home</a></Link></li>
                    <li><FontAwesomeIcon className="icon" icon={faAngleRight} /><Link to={`/aboutUs`}><a href="#!">About Us</a></Link></li>
                    <li><FontAwesomeIcon className="icon" icon={faAngleRight} /><Link to={`/services`}><a href="#!">Services</a></Link></li>
                    <li><FontAwesomeIcon className="icon" icon={faAngleRight} /><Link to={`/product`}><a href="#!">Products</a></Link></li>
                    <li><FontAwesomeIcon className="icon" icon={faAngleRight} /><Link to={`/galleryUs`}><a href="#!">Gallery</a></Link></li>
                    <li><FontAwesomeIcon className="icon" icon={faAngleRight} /><Link to={`/contact`}><a href="#!">Contact</a></Link></li>
                </ul>
            </div>
        </div>
    );
};

export default FooterBox3;