import React from 'react';
import Gallery from '../../Home/Gallery/Gallery';
import HeaderTop from '../../Share/HeaderTop/HeaderTop';
import Footer from '../../Share/Footer/Footer';
import Navbar from '../../Share/Navbar/Navbar';
import Subscriber from '../../Share/Subscriber/Subscriber';
import GalleryHeroArea from '../GalleryHeroArea/GalleryHeroArea';

const GalleryUs = () => {
    return (
        <>
            <HeaderTop></HeaderTop>
            <Navbar></Navbar>
            <GalleryHeroArea></GalleryHeroArea>
            <Gallery></Gallery>
            <Subscriber></Subscriber>
            <Footer></Footer>
        </>
    );
};

export default GalleryUs;