import React, { useState } from 'react';
import ProductsFakeData from '../../FakeData/ProductsFakeData';
import './Products.css';
import ProductsCard from './ProductsCard/ProductsCard';

const Products = () => {
    const [products, setProducts] = useState(ProductsFakeData);
    return (
        <section className="products-section">
            <h2 className="section-title text-center" data-aos="fade-up" data-aos-anchor-placement="top-bottom"><span>Products</span> Collection</h2>
            <div className="all-products">
                <div className="products-wrapper-collection">
                    <div className="row">
                        {
                            products.map(products => <ProductsCard key={products.id} products={products}></ProductsCard>)
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Products;