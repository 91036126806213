import React from 'react';

const NoMatch = () => {
    const style = {
        backgroundColor: "#020066",
        color: "#fff",
        padding: "50px 0px",
        marginTop: "15%"
    }
    return (
        <section className="noMatch-section" style={style}>
            <div className="container">
                <div className="noMatch-content text-center">
                    <h1 className="font-weight-bold">404</h1>
                    <h3 className="font-weight-bold">Page Not-Found</h3>
                    <h5 className="font-weight-bold">Mount Construction Chemicals Engineering Limited</h5>
                </div>
            </div>
        </section>
    );
};

export default NoMatch;