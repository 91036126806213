import React from 'react';
import './ServiceHeroArea.css'

const ServiceHeroArea = () => {
    return (
        <section className="service-hero-section">
            <div className="service-bgOverlay">
                <div className="container">
                    <div className="service-content">
                        <h4 data-aos="fade-up">Home/ Services</h4>
                        <h2 data-aos="fade-down">Services</h2>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServiceHeroArea;