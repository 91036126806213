import React from 'react';
import HeaderTop from '../../Share/HeaderTop/HeaderTop';
import Services from '../../Home/Services/Services';
import Footer from '../../Share/Footer/Footer';
import Navbar from '../../Share/Navbar/Navbar';
import Subscriber from '../../Share/Subscriber/Subscriber';
import ServiceHeroArea from '../ServiceHeroArea/ServiceHeroArea';
import MoreServices from '../MoreServices/MoreServices/MoreServices';

const Service = () => {
    return (
        <>
            <HeaderTop></HeaderTop>
            <Navbar></Navbar>
            <ServiceHeroArea></ServiceHeroArea>
            <Services showAll></Services>
            <MoreServices></MoreServices>
            <Subscriber></Subscriber>
            <Footer></Footer>
        </>
    );
};

export default Service;