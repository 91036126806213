import React from 'react';

const NavigationMap = () => {
    return (
        <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58421.42567597896!2d90.3553778052229!3d23.77093600214527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c1f354f7e7c5%3A0x2710aee2ba5b3bf6!2sMount%20construction%20chemicals%20Engineering%20ltd!5e0!3m2!1sen!2sbd!4v1620381439521!5m2!1sen!2sbd" width="100%" height="400" />
        </div>
    );
};

export default NavigationMap;