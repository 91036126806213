import React from 'react';
import './ContactUs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';

const ContactUs = () => {
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('gmail', 'template_v6b203j', e.target, 'user_oXA1cUdrVGytSgDCWXWwq')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
    };

    return (
        <section className="contact-section">
            <div className="container">
                <div className="contact-wrapper">
                    <div className="contact-inner">
                        <div className="row">
                            <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                                <div className="contact-form" data-aos="fade-up">
                                    <form onSubmit={sendEmail}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <input type="text" name="name" className="form-control" autoComplete="off" placeholder="Name *" />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <input type="email" name="email" className="form-control" autoComplete="off" placeholder="Email Address *" />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <input type="number" name="number" className="form-control" autoComplete="off" placeholder="Phone Number *" />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <input type="text" name="company" className="form-control" autoComplete="off" placeholder="Company *" />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <textarea type="text" name="message" className="form-control" rows="4" placeholder="Your Note *" style={{position: 'relative !important', zIndex: 'auto !important'}}></textarea>
                                            </div>
                                            <div className="contact-btn">
                                                <input type="submit" value="Send Message" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-12 col-12"ta-aos="fade-up-left">
                                <div className="contact-content" data-aos="fade-down">
                                    <h4>Mount Construction Chemicals Engineering Limited(MCCEL)</h4>
                                    <div className="contact-phone">
                                        <p><FontAwesomeIcon className="icon" icon={faPhone} />+880-1718-919505, +880-1715-403993</p>
                                    </div>
                                    <div className="contact-address">
                                        <p><FontAwesomeIcon className="icon" icon={faEnvelope} />Email: info@mountbd.org</p>
                                    </div>
                                    <div className="contact-workingTime">
                                        <p><FontAwesomeIcon className="icon" icon={faMapMarkerAlt} />Address: 99/2, Ground floor, West Shewrapara, Pirerbag road, Mirpur, Dhaka-1216</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;