import React from 'react';
import './GalleryMenubar.css';

const GalleryMenubar = ( {selectGallery, setSelectGallery} ) => {
    return (
        <div className="gallery-menuBar" data-aos="fade-up" data-aos-duration="1000">
            <div className="gallery-mainMenu">
                <ul className="d-flex">
                    <li className={selectGallery === "All" ? "active" : ""} onClick={() => setSelectGallery("All")}>All</li>
                    <li className={selectGallery === "Gallery1" ? "active" : ""} onClick={() => setSelectGallery("Gallery1")}>Gallery1</li>
                    <li className={selectGallery === "Gallery2" ? "active" : ""} onClick={() => setSelectGallery("Gallery2")}>Gallery2</li>
                    <li className={selectGallery === "Gallery3" ? "active" : ""} onClick={() => setSelectGallery("Gallery3")}>Gallery3</li>
                    <li className={selectGallery === "Gallery4" ? "active" : ""} onClick={() => setSelectGallery("Gallery4")}>Gallery4</li>
                </ul>
            </div>
        </div>
    );
};

export default GalleryMenubar;