import React from 'react';
import './FooterBox4.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGlobe, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';

const FooterBox4 = () => {
    return (
        <div className="footer-box4">
            <h4 className="footer_title">CONTACT US</h4>
            <div className="contacts">
                <div className="contact_icon"><FontAwesomeIcon className="icon" icon={faHome} /></div>
                <div className="contact_desc"><p>99/2, Middle Pirerbag <br/>Mirpur, Dhaka-1216</p></div>
            </div>
            <div className="contacts">
                <div className="contact_icon"><FontAwesomeIcon className="icon" icon={faPhone} /></div>
                <div className="contact_desc"><p>+880-248-110528 <br/>+880-1718-919505, <br/>+880-1715-403993</p></div>
            </div>
            <div className="contacts">
                <div className="contact_icon"><FontAwesomeIcon className="icon" icon={faEnvelope} /></div>
                <div className="contact_desc"><p>info@mountbd.org <br/>mccelbd@gmail.com</p></div>
            </div>
            <div className="contacts">
                <div className="contact_icon"><FontAwesomeIcon className="icon" icon={faGlobe} /></div>
                <div className="contact_desc"><a href="http://mountbd.org/" target="_blank" rel="mountbd noreferrer">www.mountbd.org</a></div>
            </div>
        </div>
    );
};

export default FooterBox4;