import React from 'react';
import './MoreServicesMenu.css';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MoreServicesMenu = ( {selectedMoreMenu, setSelectedMoreMenu} ) => {
    return (
        <div className="service-menu">
            <ul>
                <li className={selectedMoreMenu === "SurfaceTreatments" ? "active" : ""} onClick={() => setSelectedMoreMenu("SurfaceTreatments")}>SurfaceTreatments<FontAwesomeIcon className="ser-icon" icon={faAngleDoubleRight} /></li>
                <li className={selectedMoreMenu === "Concrete Repair & Renovation" ? "active" : ""} onClick={() => setSelectedMoreMenu("Concrete Repair & Renovation")}>Concrete Repair & Renovation<FontAwesomeIcon className="ser-icon" icon={faAngleDoubleRight} /></li>
                <li className={selectedMoreMenu === "Protective Coatings" ? "active" : ""} onClick={() => setSelectedMoreMenu("Protective Coatings")}>Protective Coatings<FontAwesomeIcon className="ser-icon" icon={faAngleDoubleRight} /></li>
                <li className={selectedMoreMenu === "Polish Concrete" ? "active" : ""} onClick={() => setSelectedMoreMenu("Polish Concrete")}>Polish Concrete<FontAwesomeIcon className="ser-icon" icon={faAngleDoubleRight} /></li>
                <li className={selectedMoreMenu === "Fair Face Work" ? "active" : ""} onClick={() => setSelectedMoreMenu("Fair Face Work")}>Fair Face Work<FontAwesomeIcon className="ser-icon" icon={faAngleDoubleRight} /></li>
            </ul>
        </div>
    );
};

export default MoreServicesMenu;