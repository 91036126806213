import React from 'react';
import './ContactHeroArea.css';

const ContactHeroArea = () => {
    return (
        <section className="contact-hero-section">
            <div className="contact-bgOverlay">
                <div className="container">
                    <div className="contactHero-content text-center">
                        <h5 data-aos="fade-up">Home / Contact Us</h5>
                        <h2 data-aos="fade-down">Contact Us</h2>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactHeroArea;