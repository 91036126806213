import React from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Component/Home/Home/Home";
import AboutUs from "./Component/About/AboutUs/AboutUs";
import Service from "./Component/Services/Service/Service";
import Blog from "./Component/Blog/Blog/Blog";
import GalleryUs from "./Component/GalleryUs/GalleryUs/GalleryUs";
import Product from "./Component/Product/Product/Product";
import Contact from "./Component/Contact/Contact/Contact";
import NoMatch from "./Component/NoMatch/NoMatch";

function App() {
  AOS.init({
    delay: 1,
    duration: 1000,
  });
  return (
    <>
      <Router>
        <Switch>
          <Route path="/Home"><Home /></Route>
          <Route path="/aboutUs"><AboutUs /></Route>
          <Route path="/services"><Service /></Route>
          <Route path="/product"><Product /></Route>
          <Route path="/galleryUs"><GalleryUs /></Route>
          <Route path="/blog"><Blog showAll /></Route>
          <Route path="/contact"><Contact /></Route>
          <Route exact  path="/"><Home /></Route>
          <Route path="*"><NoMatch /></Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
