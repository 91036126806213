import React from 'react';
import './HeroArea.css';
import slider1 from '../../../Assets/slider-1.jpg';
import slider2 from '../../../Assets/slider-2.png';
import slider3 from '../../../Assets/slider-3.jpg';
import slider4 from '../../../Assets/slider-4.jpg';

const HeroArea = () => {
    return (
        <section className="hero-area-section">
            <div id="carouselExampleFade" className="carousel slide carousel-fade" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#carouselExampleFade" data-slide-to="0" className="active"></li>
                    <li data-target="#carouselExampleFade" data-slide-to="1"></li>
                    <li data-target="#carouselExampleFade" data-slide-to="2"></li>
                    <li data-target="#carouselExampleFade" data-slide-to="3"></li>
                </ol>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={slider1} className="d-block w-100" alt="" />
                        <div className="bg-overlay">
                            <div className="carousel-caption">
                                <h1 data-aos="fade-up">Mount Construction Chemicals Engineering Limited</h1>
                                <h4 data-aos="fade-down">Complete solution for construction</h4>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={slider2} className="d-block w-100" alt="" />
                        <div className="bg-overlay">
                            <div className="carousel-caption">
                                <h1 data-aos="fade-up">Mount Construction Chemicals Engineering Limited</h1>
                                <h4 data-aos="fade-down">Complete solution for construction</h4>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={slider3} className="d-block w-100" alt="" />
                        <div className="bg-overlay">
                            <div className="carousel-caption">
                                <h1 data-aos="fade-up">Mount Construction Chemicals Engineering Limited</h1>
                                <h4 data-aos="fade-down">Complete solution for construction</h4>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={slider4} className="d-block w-100" alt="" />
                        <div className="bg-overlay">
                            <div className="carousel-caption">
                                <h1 data-aos="fade-up">Mount Construction Chemicals Engineering Limited</h1>
                                <h4 data-aos="fade-down">Complete solution for construction</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
        </section>
    );
};

export default HeroArea;