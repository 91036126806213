import React from 'react';
import './About.css';
import aboutImage from '../../../Assets/AboutImages/about-work.jpg';
import { useState } from 'react';


const About = () => {
    const [showMore, setShowMore] = useState(false);
    
    return (
        <section className="about-section">
            <div className="container">
                <h2 className="section-title text-center" data-aos="fade-up" data-aos-anchor-placement="top-bottom">About <span>Us</span></h2>
                <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                        <div className="about-content" data-aos="fade-up" data-aos-duration="1000">
                            <h4>WE ARE EXPERT IN</h4>
                            <h2>Mount construction chemicals Engineering limited (MCCEL)</h2>
                            <p>Mount Construction Chemicals Engineering Ltd. lanced its glorious Journey on 2018.Mount is The Unique Name For Constriction related all Types of Organization. We also provide all types of Concrete solution with the best satisfactory engineering services. <br />Our company is working with new construction sector. Also repair and renovation work of old structure. Our goal is to develop on the industrial and construction segments with a wide range of quality products. </p>
                            {
                                showMore ?
                                    
                                    <p>By introducing environment friendly products, we will be implemented a sound construction and healthy environment. We are doing the prestigious work through its well-equipped technical service department.</p>

                                :null
                            }
                            <div className="about-btn">
                                <button onClick={() => setShowMore(!showMore)}>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                        <div className="about-img" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
                            <img src={aboutImage} className="img-fluid" alt="About_img"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;