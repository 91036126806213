import React from 'react';
import HeaderTop from '../../Share/HeaderTop/HeaderTop';
import Products from '../../Home/Products/Products';
import Footer from '../../Share/Footer/Footer';
import Navbar from '../../Share/Navbar/Navbar';
import Subscriber from '../../Share/Subscriber/Subscriber';
import ProductHeroArea from '../ProductHeroArea/ProductHeroArea';

const Product = () => {
    return (
        <>
            <HeaderTop></HeaderTop>
            <Navbar></Navbar>
            <ProductHeroArea></ProductHeroArea>
            <Products></Products>
            <Subscriber></Subscriber>
            <Footer></Footer>
        </>
    );
};

export default Product;