import React from 'react';
import './AboutHeroArea.css';

const AboutHeroArea = () => {
    return (
        <section className="about-hero-section">
            <div className="about-bgOverlay">
                <div className="container">
                    <div className="about-hero-content text-center">
                        <h5 data-aos="fade-up">Home/ About Us</h5>
                        <h2 data-aos="fade-down">About Us</h2>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutHeroArea;