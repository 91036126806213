import React from 'react';
import './Footer.css';
import FooterBox1 from './FooterBox1/FooterBox1';
import FooterBox2 from './FooterBox2/FooterBox2';
import FooterBox3 from './FooterBox3/FooterBox3';
import FooterBox4 from './FooterBox4/FooterBox4';

const Footer = () => {
    return (
        <div className="footer-section">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-4"><FooterBox1></FooterBox1></div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-4"><FooterBox2></FooterBox2></div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-4"><FooterBox3></FooterBox3></div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-4"><FooterBox4></FooterBox4></div>
                </div>
            </div>
            <div className="footer-copyright">
                <div className="container">
                    <div className="copyright-content">
                        <div className="copy_right_text">
                            <p>Copyright &copy;2019 All rights reserved | Developed by <span><a href="#!">Pranto Shikder</a></span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;