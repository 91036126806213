import React, { useState } from 'react';
import './Services.css';
import serImage from '../../../Assets/ServicesImage/ser-img.jpg';
import ServicesMenu from './ServicesMenu/ServicesMenu';
import ServiceFakeData from '../../FakeData/ServiceFakeData';
import ServicesCard from './ServicesCard/ServicesCard';
import { Link } from 'react-router-dom';

const Services = ( {showAll} ) => {
    const [serviceItem, setServiceItem] = useState(ServiceFakeData);
    const [selectedMenu, setSelectedMenu] = useState("Admixture");
    return (
        <section className="services-section">
            <div className="container">
                <h2 className="section-title text-center" data-aos="fade-up" data-aos-anchor-placement="top-bottom"><span>Our</span> Services</h2>
                <div className="row">
                    <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <ServicesMenu showAll selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu}></ServicesMenu>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                                <div data-aos="fade-up">
                                    {
                                        serviceItem.filter((Admixture) => selectedMenu === Admixture.category).map(serviceItem => <ServicesCard key={serviceItem.id} serviceItem={serviceItem}></ServicesCard>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12" data-aos="fade-down">
                        <div className="ser-img">
                            <img src={serImage} className="img-fluid"alt=""/>
                        </div>
                    </div>
                    {
                        !showAll && (
                            <div className="showMore-btn">
                                <Link className="service-btn" to={`/services`}>More Services</Link>
                            </div>
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default Services;