import React from 'react';
import './FooterBox1.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGoogle, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import footerLogo from '../../../../Assets/headerLogo.png';
import { Link } from 'react-router-dom';

const FooterBox1 = () => {
    return (
        <div className="footer-box1">
            <h4 className="footer_title">ABOUT US</h4>
            <Link to="/"><img src={ footerLogo } alt=""/></Link>
            <p>Mount Construction Chemicals Engineering Ltd. lanced its glorious Journey on 2018.Mount is The Unique Name For Constriction related all Types of Organization.</p>
            <div className="footer_social">
                <ul>
                    <li><a href="https://www.facebook.com/Mount-Construction-Chemicals-Engineering-ltd-109563461278670" target="blank"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                    <li><a href="https://twitter.com/mountbd1" target="blank"><FontAwesomeIcon icon={faTwitter} /></a></li>
                    <li><a href="https://www.linkedin.com/in/mount-bd-353727211/" target="blank"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                    <li><a href="mailto:info@mountbd.org"><FontAwesomeIcon icon={faGoogle} /></a></li>
                    <li><a href="https://www.instagram.com/mountbd05/" target="blank"><FontAwesomeIcon icon={faInstagram} /></a></li>
                </ul>
            </div>
        </div>
    );
};

export default FooterBox1;