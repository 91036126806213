import React, { useState } from 'react';
import './MoreServices.css';
import AOS from 'aos';
import MoreServicesMenu from '../MoreServicesMenu/MoreServicesMenu';
import MoreServicesCard from '../MoreServicesCard/MoreServicesCard';
import ServiceFakeData from '../../../FakeData/ServiceFakeData';

const MoreServices = () => {
    AOS.init({
        delay: 1,
        duration: 700,
    });
    const [serviceMoreItem, setServiceMoreItem] = useState(ServiceFakeData);
    const [selectedMoreMenu, setSelectedMoreMenu] = useState("SurfaceTreatments");
    return (
        <section className="services-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12" data-aos="fade-down">
                        <MoreServicesMenu selectedMoreMenu={selectedMoreMenu} setSelectedMoreMenu={setSelectedMoreMenu}></MoreServicesMenu>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                        <div>
                            {
                                serviceMoreItem.filter((SurfaceTreatments) => selectedMoreMenu === SurfaceTreatments.category).map(serviceMoreItem => <MoreServicesCard key={serviceMoreItem.id} serviceMoreItem={serviceMoreItem}></MoreServicesCard>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MoreServices;