import React from 'react';
import './BlogsCard.css';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

const BlogsCard = ( props ) => {
    const { blogsImage, blogsTitle, blogsDescription, blogsPost } = props.blogsNews;
	const [showContent, setShowContent] = useState(false);
    return (
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-3">
            <div className="single-blogs-news" data-aos="zoom-in">
				<div className="blogs-img">
					<img src={blogsImage} className="img-fluid" alt="" />
				</div>
				<div className="blogs-news-info">
					<div className="blogsNews-category">
						<span>11 Sep, 2019</span>
						<a href="#!">Comments (0)</a>
					</div>
					<div className="BlogsNews-desc">
						<h2><a href="#!">{blogsTitle}</a></h2>
						<p>{blogsDescription}</p>
						{
							showContent ? <p>{blogsPost}</p> : null
						}
						<div className="blogs-btn">
							<button onClick={() => setShowContent(!showContent)}>Read More<FontAwesomeIcon className="blogs-icon" icon={faAngleDoubleRight} /></button>
						</div>
					</div>
				</div>
			</div>
        </div>
    );
};

export default BlogsCard;