import React from 'react';
import About from '../../Home/About/About';
import CompanyClients from '../../Home/CompanyClients/CompanyClients';
import HeaderTop from '../..//Share/HeaderTop/HeaderTop';
import Footer from '../../Share/Footer/Footer';
import Navbar from '../../Share/Navbar/Navbar';
import Subscriber from '../../Share/Subscriber/Subscriber';
import AboutHeroArea from '../AboutHeroArea/AboutHeroArea';

const AboutUs = () => {
    return (
        <>
            <HeaderTop></HeaderTop>
            <Navbar></Navbar>
            <AboutHeroArea></AboutHeroArea>
            <About></About>
            <CompanyClients></CompanyClients>
            <Subscriber></Subscriber>
            <Footer></Footer>
        </>
    );
};

export default AboutUs;